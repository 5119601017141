//Singleton script tha contains all the request uses in login component
import axios from "axios";
import Cookies from "universal-cookie";

//Main path builds depending on the cookie save when user select a platform and server in login form
const cookies = new Cookies();
const url = cookies.get("url");


let baseUrl = `${url}/api`;

//Function in charge of updating baseUrl variable with the new value catching on event listener
function manejarCookieActualizada(event) {
        baseUrl = `${event.detail.value}/api`;
      }
      
// Event listener for updating url variable when is change in login form
window.addEventListener('updatedUrl', manejarCookieActualizada);

//Request that bring the list of Signal providers available
const login = async credentials => {
        const res= await axios.post(`${baseUrl}/login`, credentials);
        return res;       
};

const methods = { 
        login, 
};

export default methods;