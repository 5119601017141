import {Spinner} from "reactstrap";
import classes from "../../css/LoginForm.module.scss";

function Loading(props){
    if(!props.loading) return null;
    else{
        return(
            <div className={classes.loaderParent}>
                <div className={classes.loaderChildren}>
                     <Spinner color="danger"/>
                </div>              
            </div>  
        );
    }  
}

export default Loading;