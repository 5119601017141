//Follower Main component render when the user accepts disclouser adn the account type is a follower
import SidebarSubaccount from "./SidebarSubaccount";
import "../../css/MainViews.scss";
import IdleTimerContainer from "../../config/iddleTimerContainer";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { baseEnv } from "../../App";

function Subaccount(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const cookies = new Cookies();
    const user = cookies.get("user");
    const userType = cookies.get("account type"); //Gets from cookies is the user is follower type

    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //This validation protects the view in case someone access the route without do the login correctly
    if (user == null || userType !== "sub") {
        return <Navigate to={baseEnv + "/"} />
    }

    return (
        <div>
            <IdleTimerContainer></IdleTimerContainer>
            <SidebarSubaccount />
         </div>        
    );
}

export default Subaccount;