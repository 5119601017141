import '../../css/MainViews.scss';

const Footer = (props) => {

    return (
        <div className="footer">
            {window.Labels.footer_text_1} - Api Version: 1.5 - GUI Version: 1.2.03, <a href={window.Labels.footer_url_1} target="_blank">{window.Labels.footer_url_1}</a>
        </div>
    );

};

export default Footer;