//Signal provider sidebar componen render when user log in with signal provider credentials, this component managments all the requests 
//and subcomponents about signal provider profile
import React, { useEffect, useState } from "react";
import '../../css/MainViews.scss';
import logo from "../../assets/mamviewer6.png";
import PersonIcon from "../../assets/Group 29.svg";
import reload from "../../assets/re load icon.svg";
import { useInterval } from "../../config/useInterval";
import InfoIcon from "../../assets/info.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MasterInformation from "./MasterInformation";
import OperationsTable from "./OperationsTable";
import SubOperationsTable from "./SubOperationsTable";
import SubAccountOperationsTable from "./SubAccountOperationsTable";
import SubAccountsTable from "./SubAccountsTable";
import swal from "sweetalert2";
import Tooltip from '@mui/material/Tooltip';
import signalService from "../../services/master";
import userService from "../../services/user";
import Loading from "../gadgets/loading";
import Cookies from "universal-cookie";
import { baseEnv } from "../../App";


function Sidebar() {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [leftOpen, setLeftOpen] = useState(true);
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const [trig, setTrig] = useState(false);
    const [operationsOpen, setOperationsOpen] = useState(true);
    const [subAccountsOpen, setSubAccountsOpen] = useState(false);
    //const [idFollower, setIdFollower] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMes, setErrorMes] = useState(null);
    const [masterStatus, setMasterStatus] = useState({});
    const [subOrders, setSubOrders] = useState("");
    const [subAccountOrders, setSubAccountOrders] = useState("");
    const [idOrder, setIdOrder] = useState(0);
    const [subLogin, setSubLogin] = useState(0);
    const [allocation, setAllocation] = useState("");
    const [group, setGroup] = useState("");
    const [subAccountList, setSubAccountsList] = useState("");
    const [quantityPerPage, setQuantityPerPage] = useState(10);
    const [quantityPerPageMasterOperations, setQuantityPerPageMasterOperations] = useState(10);
    const [quantityPerPageSubOperationsMaster, setQuantityPerPageSubOperationsMaster] = useState(10);
    const [quantityPerPageSubAccounts, setQuantityPerPageSubAccounts] = useState(10);
    const [quantityPerPageSubOperations, setQuantityPerPageSubOperations] = useState(10);
    const [totalMasterOperations, setTotalMasterOperations] = useState(0);
    const [totalSubOperationsMaster, setTotalSubOperationsMaster] = useState(0);
    const [totalSubAccounts, setTotalSubAccounts] = useState(0);
    const [totalSubOperations, setTotalSubOperations] = useState(0);
    const [page, setPage] = useState(1);
    const [pageMasterOperations, setPageMasterOperations] = useState(1);
    const [pageSubOperationsMaster, setPageSubOperationsMaster] = useState(1);
    const [pageSubAccounts, setPageSubAccounts] = useState(1);
    const [pageSubOperations, setPageSubOperations] = useState(1);
    const [periodicFlag, setPeriodicFlag] = useState(false);
    const [nonPeriodicFlag, setNonPeriodicFlag] = useState(false);
    const cookies = new Cookies();
    const user = cookies.get("user");
    const url = cookies.get("url");
    const token = cookies.get("token");
    let headers = { //Headers preparation, the token is get from cookies, the same token obtained when the user log in
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + token
    };
    let leftOpen2 = leftOpen ? 'open' : 'closed';
    let operationsOn = operationsOpen ? 'ON' : 'OFF';
    let subAccountsOn = !operationsOpen ? 'ON' : 'OFF';
    let title = "";

    //-----------------------------------------------------------------------
    // Checking out MT version for showing in views and login parameter
    //-----------------------------------------------------------------------  

    const platform = cookies.get("platform");;
    if (platform === "Metatrader4") {
        title = "master.MAM MASTER TERMINAL MT4";
    }
    else if (platform === "Metatrader5") {
        title = "master.MAM MASTER TERMINAL MT5";
    }

    //-----------------------------------------------------------
    //Requests area
    //-----------------------------------------------------------

    function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //main function to call sequentially every get Endpoint in Signal Provider components
    const LoadMasterStatus = async (quantity, page, periodically) => {
        if (periodically === false) {
            setLoading(true);
            if (periodicFlag === false) {
                setNonPeriodicFlag(true);
            }
            else {
                await wait(1000);
                setPeriodicFlag(false);
            }
        }
        else {
            setLoading(loading);
        }
        //Validates if there is a request working in this moment and if so, the periodical request is skip
        if (periodically === true && nonPeriodicFlag === true) {
            return;
        }
        else {
            setPeriodicFlag(true);
        }
        //periodically === false ? setLoading(true) : setLoading(loading);//Starts loading spinning, validates if the petition is periodic
        userService.getStatus( //Calls the method getStatus from user service (axios request), its passed the headers
            headers,
            quantity,
            page,
        )
            .then(response => { //Waits for a response from the axios request  
                return response.data;
            })
            .then(response => {
                LoadOrderData(quantityPerPageSubOperationsMaster, pageSubOperationsMaster, periodically, true, idOrder, group, allocation); //Calls de function loadSignalData once finished the first request, periodically is a flag to define if the request was done by manually or automatic from time to time
                if (response.data != null) {
                    setMasterStatus(response.data); //If response is correct, sets ip into the hook ip.  
                    //setTotalMasterOperations(response.data.total_operations);
                    setSubAccountsList(response.data.sub_info);
                    setTotalSubAccounts(response.data.total_account);
                    setTotalMasterOperations(response.data.total_elements);
                    setGroup(response.data.subgroup);
                    setAllocation(response.data.allocation);
                    //console.log("Status Ok");
                }
                else {
                    console.log("not data");
                    if (!periodically) AlertMessage("master.Server error, try again", "error", "red", "");
                }

            })
            .catch(error => {
                if (error.response.data.message === "Login is required") { //Validates if the axios error was due to a login is required
                    AlertMessage("subAccount.Session finished, please log in again", "error", "red", ""); //If validation is fulfilled, A notice is displayed and redirected to login
                    setLoading(false); //Stops loading spinning 
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    console.log(error);
                    navigate(baseEnv + "/"); //Method to redirect to the login component
                }
                else {
                    if (!periodically) AlertMessage("master.Server error, try again", "error", "red", ""); //Default error message, validates if it's a periodical request        
                    setLoading(false);
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    console.log(error);
                }
            });

    }

    //Function in charge of making the configuration request of the signal provider to the communitraders api
    const LoadOrderData = async (quantity, page, periodically, chained, idOrder, group, allocation) => {
        if (idOrder !== 0 && group !== "" && allocation !== "") {
            if (!periodically) {
                setLoading(true);
                if (periodicFlag === false) {
                    setNonPeriodicFlag(true);
                }
                else if (chained === false) {
                    await wait(1000);
                    setNonPeriodicFlag(true);
                }
            }
            else {
                //LoadFollowerList(quantityPerPage, page, periodically, true);
            }
            userService.getInformation(
                headers,
                {
                    allocation: allocation,
                    master_ticket: idOrder.toString(),
                    subgroup: group,
                    quantity,
                    page,
                }
            )
                .then(response => {
                    return response.data;
                })
                .then(response => {
                    if (response.data != null) {
                        setSubOrders(response.data.list);
                        setNonPeriodicFlag(false);
                        setTotalSubOperationsMaster(response.data.total_elements)
                        //setPeriodicFlag(false);
                        setLoading(false); //Stops loading spinning              
                    }
                    else {
                    }
                })
                .catch(error => {
                    if (error.response.data.message === "Login is required") {
                        AlertMessage("subAccount.Session finished, please log in again", "error", "red", "");
                        setLoading(false); //Stops loading spinning 
                        navigate(baseEnv + "/");
                    }
                    else {
                        if (chained === true) {
                            //LoadFollowerList(quantityPerPage, page, periodically);
                        }
                        else {
                            setNonPeriodicFlag(false);
                            setPeriodicFlag(false);
                        }
                        if (!periodically) AlertMessage("master.Server error, try again", "error", "red", "");
                        setLoading(false);
                        console.log(error);
                    }
                });
        }
        else {
            setNonPeriodicFlag(false);
            setPeriodicFlag(false);
            setLoading(false);
        }
    };

    //Function in charge of making the follower list request of the signal provider to the communitraders api
    const LoadSubOrdersList = async (quantity, page, periodically, chained, login) => {
        if (periodically === false) {
            setLoading(true);
            if (periodicFlag === false) {
                setNonPeriodicFlag(true);
            }
            else if (chained === false) {
                await wait(1000);
                setNonPeriodicFlag(true);
            }
        }
        else {
            setLoading(loading);
        }
        //periodically === false ? setLoading(true) : setLoading(loading);
        signalService.getSubOrders(
            quantity,
            page,
            login,
            headers
        )
            .then(response => {
                return response.data;
            })
            .then(response => {
                setNonPeriodicFlag(false);
                setPeriodicFlag(false);
                setLoading(false); //Stops loading spinning             
                if (response.status === "Success") {
                    setSubAccountOrders(response.data.openorderssubs);
                    setTotalSubOperations(response.data.total_elements);
                }
                else {
                    if (!periodically) AlertMessage("master.Server error, try again", "error", "red", "");
                    console.log("Endpoint get master status OK but not info");
                    console.log(response);
                }
            })
            .catch(error => {
                if (error.response.data.message === "Login is required") {
                    AlertMessage("subAccount.Session finished, please log in again", "error", "red", "");
                    setLoading(false); //Stops loading spinning 
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else {
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    setLoading(false); //Stops loading spinning  
                    if (!periodically) AlertMessage("master.Server error, try again", "error", "red", "");
                    console.log(error);
                }
            }
            );
    };

    //Function in charge of making the log out request of the signal provider to the communitraders api
    async function logOut() {
        setLoading(true);
        if (periodicFlag === false) {
            setNonPeriodicFlag(true);
        }
        else {
            await wait(1000);
            setNonPeriodicFlag(true);
        }
        userService.logOut( //Calls the method stopFollowing from follower service (axios request), its passed the headers
            headers
        )
            .then(response => { //Waits for a response from the axios request
                return response.data;
            })
            .then(response => {
                setLoading(false);
                setNonPeriodicFlag(false);
                if (response.status === "Success") {
                    console.log("Log out Successful");
                }
                else {
                    console.log("Log out without response");
                }
            })
            .catch(error => {
                setLoading(false);
                setNonPeriodicFlag(false);
                console.log(error);
            }
            );
        navigate(baseEnv + "/");
    }

    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //Validates if errorMes state is true on each render, if so, its shown a modal with an error message
    if (errorMes === true) {
        AlertMessage("master.Server error, try again", "error", "red", "")
        setErrorMes(false);
    };

    // Function handler calls when user clicks on sidebar button, this function change the left sidebar state to the opposite, Used to tell the css whether or not to show the sidebar
    function toggleSidebar() {
        setLeftOpen(!leftOpen);
    };


    //Function handler calls when user clicks on an sidebar option, this allows css whether or not shows the followers table or historical settings table
    function TriggerMenu() {
        setTrig(!trig);
        if (window.innerWidth <= 550) { //if view width is lower than 550 pixels, the sidebar is hidden
            setLeftOpen(false);
        }
        if (!trig) {
            //LoadOrderData(false, false);
        }
    }

    //Function handlers user when signal provider user clicks on view follower settings, this allows signal provider views the follower setting,
    function TriggerFollowerSetting(idOrder) {
        //setIdFollower(id); //Sets the follower id that was click by signal provider 
        setIdOrder(idOrder);
        LoadOrderData(quantityPerPageSubOperationsMaster, pageSubOperationsMaster, false, false, idOrder, group, allocation);
        setTrig(false);
        //LoadFollowerSettings(id); //Calls the function in charge to handler whether or not to show that form
    }

    //Function handlers user when signal provider user clicks on view follower settings, this allows signal provider views the follower setting,
    function TriggerSubAccountOrders(subLoginId) {
        //setIdFollower(id); //Sets the follower id that was click by signal provider 
        setSubLogin(subLoginId)
        LoadSubOrdersList(10, 1, false, false, subLoginId);
        setTrig(false);
        //LoadFollowerSettings(id); //Calls the function in charge to handler whether or not to show that form
    }

    //Function handlers triggered when signal user presses on Follower list or Historical List, this function controls what components is rendered
    function triggerTables(table) {
        if (window.innerWidth <= 550) { //If view width is lower than 550 pixels, the sidebar is hidden
            setLeftOpen(false);
        }
        if (table === "operations") {
            setOperationsOpen(true);
            setSubAccountsOpen(false);
            LoadMasterStatus(quantityPerPageMasterOperations, pageMasterOperations, false);
            setQuantityPerPage(quantityPerPageMasterOperations);
            setPage(pageMasterOperations);
        }
        else if (table === "subAccounts") {
            setSubAccountsOpen(true);
            setOperationsOpen(false);
            LoadMasterStatus(quantityPerPageSubAccounts, pageSubAccounts, false);
            setQuantityPerPage(quantityPerPageSubAccounts);
            setPage(pageSubAccounts);
        }

    }

    //Function handler calls when signal providers clicks on settings close button on signal settings form, the state is send by props at childen component
    function SettingCloseHandle() {
        setTrig(false);
    }

    //Function handler calls when signal user clicks on change amount of items per page in follower list
    const OperationsPerRowsChange = (newPerPage) => {
        LoadMasterStatus(newPerPage, pageMasterOperations, false);
        setQuantityPerPageMasterOperations(newPerPage);
        setQuantityPerPage(newPerPage);
        /*    
        case "sub operations master table":
            //LoadOrderData(newPerPage, page, false, false, idOrder, group, allocation)
            setQuantityPerPageSubOperationsMaster(newPerPage);
        case "sub accounts table":
            //LoadMasterStatus(newPerPage, page, false, false)
            setQuantityPerPageSubAccounts(newPerPage);
        case "sub operations table":
            //LoadFollowerList(newPerPage, page, false, false)
            setQuantityPerPageSubOperations(newPerPage);
        default:
                break;
    }  */
    };

    //Function handler calls when signal provider clicks on change page in next or back page in follower list
    const OperationsPageChange = (page) => {
        LoadMasterStatus(quantityPerPageMasterOperations, page, false);
        setPageMasterOperations(page);
        setPage(page);
        /* 
     case "sub operations master table":
         //LoadOrderData(quantityPerPageSubOperationsMaster, page, false, false, idOrder, group, allocation)
         setPageSubOperationsMaster(page); 
     case "sub accounts table":
         //LoadMasterStatus(quantityPerPageSubAccounts, page, false, false)
         setPageSubAccounts(page); 
     case "sub operations table":
         //LoadFollowerList(quantityPerPageSubOperations, page, false, false)
         setPageSubOperations(page); 
     default:
             break;
 } */
    };

    //Function handler calls when signal user clicks on change amount of items per page in follower list
    const SubOperationsMasterPerRowsChange = (newPerPage) => {
        LoadOrderData(newPerPage, pageSubOperationsMaster, false, false, idOrder, group, allocation);
        setQuantityPerPageSubOperationsMaster(newPerPage);
    };

    //Function handler calls when signal provider clicks on change page in next or back page in follower list
    const SubOperationsMasterPageChange = (page) => {
        LoadOrderData(quantityPerPageSubOperationsMaster, page, false, false, idOrder, group, allocation);
        setPageSubOperationsMaster(page);
    };

    //Function handler calls when signal user clicks on change amount of items per page in follower list
    const SubAccountsPerRowsChange = (newPerPage) => {
        LoadMasterStatus(newPerPage, pageSubAccounts, false);
        setQuantityPerPageSubAccounts(newPerPage);
        setQuantityPerPage(newPerPage);
    };

    //Function handler calls when signal provider clicks on change page in next or back page in follower list
    const SubAccountsPageChange = (page) => {
        LoadMasterStatus(quantityPerPageMasterOperations, page, false);
        setPageSubAccounts(page);
        setPage(page);
    };

    //Function handler calls when signal user clicks on change amount of items per page in follower list
    const SubAccountOrdersPerRowsChange = (newPerPage) => {
        LoadSubOrdersList(newPerPage, pageSubOperations, false, false, subLogin);
        setQuantityPerPageSubOperations(newPerPage);
    };

    //Function handler calls when signal provider clicks on change page in next or back page in follower list
    const SubAccountOrdersPageChange = (page) => {
        LoadSubOrdersList(quantityPerPageSubOperations, page, false, false, subLogin);
        setPageSubOperations(page);
    };

    function AlertMessage(message, icon, color, quantity = "", quantityText = "") {
        swal.fire({
            text: t(message) + quantity + t(quantityText),
            icon: icon,
            iconColor: color,
            color: "#363636",
            background: "#FFFFFF",
            confirmButtonColor: "#FF0000"
        });
    };



    //-------------------------------------------------------------------------------------------
    //Periodically request
    //-------------------------------------------------------------------------------------------

    // Initial request use when the component is rendered for the first time
      useEffect(() => {
        LoadMasterStatus(quantityPerPage, page, false); //Main request function is called in here with a false argument that indicates tha the call is no periodically
        //getIp();
    }, []);

    // Periodically main request use each certain time, it works the same than the first effect, the different is this is called periodically
    useInterval(() => {
        LoadMasterStatus(quantityPerPage, page, true);

        if (subLogin !== 0) 
        { 
            LoadSubOrdersList(quantityPerPageSubOperations, pageSubOperations, true, true, subLogin); 
        }
    }, 10000);


        return (
            <div id='layout'>
                <Loading loading={loading}/>
                <div id='left' className={leftOpen2}>
                
                    <div className='icon'
                        onClick={toggleSidebar} >
                        &equiv;
                    </div>
                    <div className={`sidebar ${leftOpen2}`} >
                        <div className='header'>
                            <img
                                className='imageTitle'
                                src={logo}
                                alt="Logo"
                                htmlFor="logo-image"
                            ></img>
                        </div>
                        <div className='content'>
                            <div className='userData'>
                                {t(title)}
                                <div className='userLogin'>
                                    <img
                                        className='imageLogin'
                                        src={PersonIcon}
                                        alt="Person"
                                        htmlFor="person-icon"
                                    ></img>
                                    <div> 
                                        {masterStatus.name != null ? masterStatus.name : "No data"} <br></br><font color={window.Colors.font_color_2}>{user}</font>
                                    </div>
                                </div>                  
                            </div>
                            <div className='userSettings'>
                                <div className='labelField'>
                                    <button className={`
                                        setting
                                        ${operationsOn}
                                    `} onClick={(e) => triggerTables('operations')}> {t("master.Operations")} </button>
                                <Tooltip title={t("master.List of trades opened by the master account")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                    ></img>
                                </Tooltip>
                            </div>
                            <div className='labelField'>
                                <button className='setting' onClick={TriggerMenu}> {t("master.Master's Information")} </button>
                                <Tooltip title={t("master.Master account information panel")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                    ></img>
                                </Tooltip>
                            </div>
                            <div className='labelField'>
                                <button className={`
                                    setting
                                    ${subAccountsOn}
                                `} onClick={(e) => triggerTables('subAccounts')}> {t("master.Sub Accounts")} </button>
                                <Tooltip title={t("master.List of sub accounts linked to the master account")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                    ></img>
                                </Tooltip>
                            </div>
                            <button className='setting' onClick={logOut}> {t("master.Sign Out")} </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`
                      main
                      ${'left-' + leftOpen2}
                  `} id='main'>
                <div className='header'>
                    <h3 className={`
                      title
                      ${'left-' + leftOpen2}
                  `}>
                        <div className='statusData'>
                            <div>
                                {masterStatus.name != null ? masterStatus.name : "No data"}
                            </div>
                            <div>
                                {t("master.Balance")}   {masterStatus.balance != null ? masterStatus.balance : "no balance"}
                            </div>
                            <div>
                                {t("master.Credit")}   {masterStatus.credit != null ? masterStatus.credit : "no credit"}
                            </div>
                            <div>
                                {t("master.Equity")}    {masterStatus.equity != null ? masterStatus.equity : "no equity"}
                            </div>
                        </div>
                        <button className='reloadIcon' onClick={(e) => LoadMasterStatus(quantityPerPage, page, false)}>
                            <img
                                src={reload}
                                alt="reload Icon"
                                htmlFor="reload-icon"
                            />
                        </button>
                    </h3>
                </div>
                <div className='contentMain'>
                    <MasterInformation
                        triggerMenu={trig}
                        onClose={SettingCloseHandle}
                        setLoading={setLoading}
                        masterStatus={masterStatus}
                    />
                    <div className='contentMain2'>
                        <OperationsTable
                            triggerFollower={operationsOpen}
                            onOpenSetting={TriggerFollowerSetting}
                            operationsList={masterStatus.openordersmasters}
                            totalMasterOperations={totalMasterOperations}
                            OperationsPerRowsChange={OperationsPerRowsChange}
                            OperationsPageChange={OperationsPageChange}
                        />
                        <SubAccountsTable
                            triggerSubAccounts={subAccountsOpen}
                            onOpenSubTrades={TriggerSubAccountOrders}
                            subAccountList={subAccountList}
                            totalSubAccounts={totalSubAccounts}
                            SubAccountsPerRowsChange={SubAccountsPerRowsChange}
                            SubAccountsPageChange={SubAccountsPageChange}
                        />
                        <SubOperationsTable
                            triggerFollower={operationsOpen}
                            subOrders={subOrders}
                            masterOrder={idOrder}
                            totalSubOperationsMaster={totalSubOperationsMaster}
                            SubOperationsMasterPerRowsChange={SubOperationsMasterPerRowsChange}
                            SubOperationsMasterPageChange={SubOperationsMasterPageChange}
                        />
                        <SubAccountOperationsTable
                            triggerFollower={subAccountsOpen}
                            subAccountOrders={subAccountOrders}
                            masterOrder={idOrder}
                            totalSubOperations={totalSubOperations}
                            SubAccountOrdersPerRowsChange={SubAccountOrdersPerRowsChange}
                            SubAccountOrdersPageChange={SubAccountOrdersPageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;