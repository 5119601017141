//Follower list table component rendered when signal provider user click on Follower button in Signal main view;
import DataTable from 'react-data-table-component' 
import '../../css/DataTable.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

function OperationsTable(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [ticket, setTicket] = useState("false");
    const [filterValue, setFilterText] = React.useState('');
	
    const { t } = useTranslation("global");
    //const tabla_data = props.operationsList;
    const table_data = props.operationsList;
    let filteredData = [];
    const filterValueLowercase = filterValue.toLowerCase();


    const handleFilterChange = (e) => {
        const value = e.target.value || '';
        setFilterText(value);
      };

    if(table_data){
        
        filteredData = table_data.filter(
            (row) => {
            const columnsToValidate = [
                'login',
                'ticket',
                'open_time',
                'type',
                'volume',
                'symbol',
                'open_price',
                'sl',
                'tp',
                'price',
                'commission',
                'swap',
                'profit'
              ];
          
              return columnsToValidate.some((column) => {
                const value = row[column];
          
                if (typeof value === 'number' && !isNaN(value)) {

                  return value.toString().toLowerCase().includes(filterValueLowercase);
                }
          
                if (typeof value === 'string' && value.toLowerCase().includes(filterValueLowercase)) {
                  return true; // Filtrar si es una cadena que incluye el valor de filtro
                }
          
                return false; // No filtrar en otros casos
              });
            });
    }
    
    const customStyles = {
      rows: {
          style: {
              minHeight: '25px', // override the row height
          },
      },
    }
    //The columnas constant contains an array of all the columns that the follower table will have and its cells settings and styles
    const columnas = [
        {
            name: <div className="title-format">{t("master.Login")}</div>,
            selector: row => row['login'],
            sortable: true,
            center: true,
            wrap: true,
            minWidth: '80px',
            fixed: "left",
            style: {
                color: 'black',
                backgroundColor: '#F5F5F5',
            },
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ]
        },
        {
            name: <div className="title-format">{t("master.Ticket")}</div>,
            selector: row => row['ticket'],
            sortable: true,
            center: true,
            minWidth: '80px',
            wrap: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ]
        },       
        {
            name: <div className="title-format">{t("master.Open Time")}</div>,
            selector: row => row['open_time'],
            sortable: true,
            center: true,
            minWidth: '120px',
            wrap: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ]
        },
        {
            name: <div className="title-format">{t("master.Type")}</div>,
            selector: row => row['type'],
            sortable: true,
            center: true,
            minWidth: '80px',
            wrap: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ]
        },
        {
            name: <div className="title-format">{t("master.Volume")}</div>,
            selector: row => row['volume'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ]
        },
        {
            name: <div className="title-format">{t("master.Symbol")}</div>,
            selector: row => row['symbol'],
            sortable: true,
            center: true,
            minWidth: '80px',
            wrap: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ]
        },
        {
            name: <div className="title-format">{t("master.Open Price")}</div>,
            selector: row => row['open_price'],
            sortable: true,
            center: true,
            minWidth: '60px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ]
        },
        {
            name: <div className="title-format">{t("master.S/L")}</div>,
            selector: row => row['sl'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ]
        
        },
        {
            name: <div className="title-format">{t("master.T/P")}</div>,
            selector: row => row['tp'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ]
        },
        {
            name: <div className="title-format">{t("master.Price")}</div>,
            selector: row => row['price'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ]
        },
        {
            name: <div className="title-format">{t("master.Commission")}</div>,
            selector: row => row['commission'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.commission < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.commission > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },  
                    //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ],
        },
        {
            name: <div className="title-format">{t("master.Swap")}</div>,
            selector: row => row['swap'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.swap < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.swap > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ],
        },
        {
            name: <div className="title-format">{t("master.Profit")}</div>,
            selector: row => row['profit'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.profit < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.profit > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.ticket === ticket, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ],
        },
        {
            name: <div className="title-format">{t("master.Action")}</div>,
            selector: row => row['button'],
            sortable: true,
            button: true,
            cell: (row) => {
                return (
                      <button
                      type="button" 
                      className="button-follower" 
                      onClick={(e) => handleButtonClick(e, row.ticket)}
                      >
                        {t("master.Sub Tickets")}
                      </button>
                );
            },
            style: {
                backgroundColor: '#F5F5F5',
            }
        }      
    ];

    let menu = props.triggerFollower ? 'open' : 'closed';

    //Function handlers calls when user clicks on view settings button, this calls the props function TriggerFollowerSettings
    const handleButtonClick = (e, idOrder) => {
        props.onOpenSetting(idOrder);
        setTicket(idOrder);
    };
    
  return (
      <div className={`                     
                      ${'containerUsers-' + menu}
                  `}>
        <div className="labelTable">
            {t("master.Master Orders")}
            <input
                className='filterBox'
                type="text"
                placeholder={t("master.Search...")}
                value={filterValue}
                onChange={handleFilterChange}
            />
        </div>
        <div className="table-responsive">
          <DataTable
            columns={columnas}
            data={filteredData}
            customStyles={customStyles}
            title={""}
            pagination
            paginationComponentOptions={{ rowsPerPageText: t("master.Rows per Page"), rangeSeparatorText: t("master.of"), selectAllRowsItem: true, selectAllRowsItemText: t("master.All"),}}
            paginationServer
            paginationTotalRows={props.totalMasterOperations}
            onChangeRowsPerPage={props.OperationsPerRowsChange}
            onChangePage={props.OperationsPageChange}
            fixedHeader                       
          />
        </div>
      </div>
  );
}


export default OperationsTable;
