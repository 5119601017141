//SidebarFollower component render when the follower component validates if the user type is correct, this component contains all the
//endpoints requests and the most of the follower logic. This components has the task to send the different hooks and function via props
import React, { useState, useEffect } from "react";
import '../../css/MainViews.scss';
import SubordersTable from "./SubordersTable";
import SubAccountInfo from "./SubAccountInformation";
import logo from "../../assets/mamviewer6.png";
import PersonIcon from "../../assets/Group 29.svg";
import reload from "../../assets/re load icon.svg";
import InfoIcon from "../../assets/info.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInterval } from "../../config/useInterval";
import swal from "sweetalert2";
import Tooltip from '@mui/material/Tooltip';
import userService from "../../services/user";
import Cookies from "universal-cookie";
import Loading from "../gadgets/loading";
import { baseEnv } from "../../App";


function SubAccountSidebar() {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [leftOpen, setLeftOpen] = useState(true);
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const [trig, setTrig] = useState(false);
    const [subAccountData, setSubAccountData] = useState({});
    const [subOrdersList, setSubOrdersList] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMes, setErrorMes] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [quantityPerPage, setQuantityPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [periodicFlag, setPeriodicFlag] = useState(false);
    const [nonPeriodicFlag, setNonPeriodicFlag] = useState(false);
    const cookies = new Cookies();
    const user = cookies.get("user");
    const token = cookies.get("token");
    let headers = { //Headers preparation, the token is get from cookies, the same token obtained when the user log in
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + token
    };
    let leftOpen2 = leftOpen ? 'open' : 'closed';
    let title = "";

    //-----------------------------------------------------------------------
    // Checking out MT version for showing in views and login parameter
    //-----------------------------------------------------------------------  

    const platform = cookies.get("platform");;
    if (platform === "Metatrader4") {
        title = "subAccount.SUB ACCOUNT TERMINAL MT4";
    }
    else if (platform === "Metatrader5") {
        title = "subAccount.SUB ACCOUNT TERMINAL MT5";
    }

    //-----------------------------------------------------------
    //Requests area
    //-----------------------------------------------------------

    function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //main function to call sequentially every "get" Endpoint in follower component
    const LoadSubAccountData = async (quantity, page, periodically) => {
        if (periodically === false) {
            setLoading(true);
            if (periodicFlag === false) {
                setNonPeriodicFlag(true);
            }
            else {
                await wait(1000);
                setPeriodicFlag(false);
            }
        }
        //Validates if there is a request working in this moment and if so, the periodical request is skip
        if (periodically === true && nonPeriodicFlag === true) {
            return;
        }
        else {
            setPeriodicFlag(true);
        }
        //Starts loading spinning, validates if the petition is periodic
        userService.getStatus( //Calls the method getStatus from user service (axios request), its passed the headers
            headers,
            quantity,
            page,
        )
            .then(response => {  //Waits for a response from the axios request           
                return response.data;
                setLoading(false);
            })
            .then(response => {
                //LoadSubAccountDataSettings(periodically, true); //Calls de function loadFollowerDataSettings once finished the first request
                if (response.data != null) {
                    setSubAccountData(response.data); //If response is correct, sets ip into the hook ip.
                    setSubOrdersList(response.data.openorderssubs);
                    setTotalRows(response.data.total_elements);
                    setLoading(false);
                    setNonPeriodicFlag(false);
                }
                else {
                    console.log("not data");
                    setLoading(false);
                    setNonPeriodicFlag(false);
                }
            })
            .catch(error => {
                if (error.response.data.message === "Login is required") {
                    AlertMessage("subAccount.Session finished, please log in again", "error", "red");
                    setNonPeriodicFlag(false);
                    setPeriodicFlag(false);
                    setLoading(false);
                    if (!periodically) setLoading(false);
                    console.log(error);
                    navigate(baseEnv + "/");
                }
                else {
                    periodically === false ? setErrorMes(true) : setErrorMes(false); //Default error message, validates if it's a periodical request
                    setNonPeriodicFlag(false);
                    setLoading(false);
                    setPeriodicFlag(false);
                    if (!periodically) setLoading(false); //Stops loading spinning  
                    console.log(error);
                }

            });
    };

    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //Function handler that re calls the method to consult the list of Available Signal providers with a new number of rows per page
    const handlePerRowsChange = (newPerPage, page) => {
        LoadSubAccountData(newPerPage, page, false, false);
        setQuantityPerPage(newPerPage);
    };

    //Function handler that re calls the method to consult the list of Available Signal providers with a new number of page
    const handlePageChange = page => {
        LoadSubAccountData(quantityPerPage, page, false, false);
        setPage(page);
    };

    //Validates if the message state was true and then throw a modal message error
    if (errorMes === true) {
        swal.fire({
            text: t("master.Server error, try again"),
            icon: "error",
            iconColor: "red",
            color: "#363636",
            background: "#FFFFFF",
            confirmButtonColor: "#FF0000"
        });
        setErrorMes(false);
    };

    // Message function, this function is used for others function to show modal success and error messages
    function AlertMessage(message, icon, color) {
        swal.fire({
            text: t(message),
            icon: icon,
            iconColor: color,
            color: "#363636",
            background: "#FFFFFF",
            confirmButtonColor: "#FF0000"
        });
    };

    //Function handler triggered when the follower users press the different option to move between signal provider list, historical and settings components.
    function toggleSidebar() {
        setLeftOpen(!leftOpen);
    };

    //Function handler triggered when user presses the button log out, it sends directly to login view.
    async function logOut() {
        setLoading(true);
        if (periodicFlag === false) {
            setNonPeriodicFlag(true);
        }
        else {
            await wait(1000);
            setNonPeriodicFlag(true);
        }
        userService.logOut( //Calls the method stopFollowing from follower service (axios request), its passed the headers
            headers
        )
            .then(response => { //Waits for a response from the axios request
                return response.data;
            })
            .then(response => {
                setLoading(false);
                setNonPeriodicFlag(false);
                if (response.status === "Success") {
                    console.log("Log out Successful");
                }
                else {
                    console.log("Log out without response");
                }
            })
            .catch(error => {
                setLoading(false);
                setNonPeriodicFlag(false);
                console.log(error);
            }
            );
        navigate(baseEnv + "/");
    }

    //Function handler triggered when follower user presses on settings button, this allows to deploy the settigns sidebar and reload follower settings data
    function triggerInformation() {
        if (window.innerWidth <= 550) { //Responsive adjust, if so, this hidden the main sidebar
            setLeftOpen(false);
        };
        if (!trig) {
            //LoadSubAccountDataSettings(false, false);
        };
        setTrig(!trig);

    }

    //Function handler triggered when follower user presses on close setting modal button, and allows to close the setting sidebar
    function settingCloseHandle() {
        setTrig(false);
    }

    //-------------------------------------------------------------------------------------------
    //Periodically request
    //-------------------------------------------------------------------------------------------

    //USe Effect hook triggered once per render, it calls the main request function, which gets all the follower information
    useEffect(() => {
        LoadSubAccountData(quantityPerPage, page, false);
    }, []);

    //use Effect hook triggered every time the adjusted time is reached, it calls the main request function to refresh periodically all the follower data
    useInterval(() => {
        LoadSubAccountData(quantityPerPage, page, true);
    }, 10000)


    return (
        <div id='layout'>

            <Loading loading={loading} />
            <div id='left' className={leftOpen2} >
                <div className='icon'
                    onClick={toggleSidebar} >
                    &equiv;
                </div>
                <div className={`sidebar ${leftOpen2}`} >
                    <div className='header'>
                        <img
                            className='imageTitle'
                            src={logo}
                            alt="Logo"
                            htmlFor="logo-image"
                        ></img>
                    </div>
                    <div className='content'>
                        <div className='userData'>
                            {t(title)}
                            <div className='userLogin'>
                                <img
                                    className='imageLogin'
                                    src={PersonIcon}
                                    alt="Person Icon"
                                    htmlFor="person-icon"
                                ></img>
                                <div>
                                    {subAccountData.name != null ? subAccountData.name : "No data"}<br></br><font color="gray">{user}</font>
                                </div>
                            </div>
                        </div>
                        <div className='userSettings'>
                            <div className='labelField'>
                                <button className={`
                                        setting
                                        ON
                                    `} > {t("master.Operations")} </button>
                                <Tooltip title={t("subAccount.List of trades opened by the subaccount")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                    ></img>
                                </Tooltip>
                            </div>
                            <div className='labelField'>
                                <button className="setting"
                                    onClick={triggerInformation}> {t("subAccount.Account's Information")} </button>
                                <Tooltip title={t("subAccount.Sub account information panel")}>
                                    <img
                                        className='imageTitle'
                                        src={InfoIcon}
                                        alt="info"
                                        htmlFor="info-icon"
                                        data-tip data-for="infoTooltip"
                                    ></img>
                                </Tooltip>
                            </div>
                            <button className='setting' onClick={logOut}> {t("master.Sign Out")} </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`
                      main
                      ${'left-' + leftOpen2}
                  `} id='main'>
                <div className='header'>
                    <h3 className={`
                      title
                      ${'left-' + leftOpen2}
                  `}>
                        <div className='statusData'>
                            <div>
                                {subAccountData.name != null ? subAccountData.name : "No data"}
                            </div>
                            <div>
                                {t("master.Balance")}   {subAccountData.balance != null ? subAccountData.balance : "no balance"}
                            </div>
                            <div>
                                {t("master.Credit")}   {subAccountData.credit != null ? subAccountData.credit : "no credit"}
                            </div>
                            <div>
                                {t("master.Equity")}    {subAccountData.equity != null ? subAccountData.equity : "no equity"}
                            </div>
                        </div>
                        <button className='reloadIcon' onClick={(e) => LoadSubAccountData(quantityPerPage, page, false)}>
                            <img
                                src={reload}
                                alt="reload Icon"
                                htmlFor="reload-icon" />
                        </button>
                    </h3>
                </div>
                <div className='contentMain'>
                    <SubAccountInfo
                        triggerMenu={trig}
                        onClose={settingCloseHandle}
                        subAccountData={subAccountData}
                        setLoading={setLoading}
                    />
                    <SubordersTable
                        triggerFollower={true}
                        operationsList={subOrdersList}
                        totalRows={totalRows}
                        handlePerRowsChange={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default SubAccountSidebar;