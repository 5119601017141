//Follower list table component rendered when signal provider user click on Follower button in Signal main view;
import DataTable from 'react-data-table-component' 
import '../../css/DataTable.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useTranslation } from "react-i18next";
import { useState } from "react";

function SubAccountOperationsTable(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [filterValue, setFilterText] = useState('');
    const { t } = useTranslation("global");
    const filteredItems = props.subAccountOrders;
    let filteredData = [];
    const filterValueLowercase = filterValue.toLowerCase();

    const handleFilterChange = (e) => {
        const value = e.target.value || '';
        setFilterText(value);
      };

    if(filteredItems){
        
        filteredData = filteredItems.filter(
            (row) => {
            const columnsToValidate = [
                'login',
                'ticket',
                'linked_ticket',
                'open_time',
                'type',
                'volume',
                'symbol',
                'open_price',
                'sl',
                'tp',
                'price',
                'commission',
                'swap',
                'profit'
              ];
          
              return columnsToValidate.some((column) => {
                const value = row[column];
          
                if (typeof value === 'number' && !isNaN(value)) {

                  return value.toString().toLowerCase().includes(filterValueLowercase);
                }
          
                if (typeof value === 'string' && value.toLowerCase().includes(filterValueLowercase)) {
                  return true; // Filtrar si es una cadena que incluye el valor de filtro
                }
          
                return false; // No filtrar en otros casos
              });
            });
    }

    const customStyles = {
      rows: {
          style: {
              minHeight: '25px', // override the row height
          },
      },
    }
    //The columnas constant contains an array of all the columns that the follower table will have and its cells settings and styles
    const columnas = [
        {
            name: <div className="title-format">{t("master.Login")}</div>,
            selector: row => row['login'],
            sortable: true,
            center: true,
            wrap: true,
            minWidth: '80px',
            fixed: "left",
            style: {
                color: 'black',
                backgroundColor: '#F5F5F5',
            },
        },
        {
            name: <div className="title-format">{t("master.Ticket")}</div>,
            selector: row => row['ticket'],
            sortable: true,
            center: true,
            minWidth: '80px',
            wrap: true,
        },     
        {
            name: <div className="title-format">{t("master.Linked Ticket")}</div>,
            selector: row => row['linked_ticket'],
            sortable: true,
            center: true,
            minWidth: '80px',
            wrap: true,
        },
        {
            name: <div className="title-format">{t("master.Open Time")}</div>,
            selector: row => row['open_time'],
            sortable: true,
            center: true,
            minWidth: '120px',
            wrap: true,
        },
        {
            name: <div className="title-format">{t("master.Type")}</div>,
            selector: row => row['type'],
            sortable: true,
            center: true,
            minWidth: '80px',
            wrap: true,
        },
        {
            name: <div className="title-format">{t("master.Volume")}</div>,
            selector: row => row['volume'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("master.Symbol")}</div>,
            selector: row => row['symbol'],
            sortable: true,
            center: true,
            minWidth: '80px',
            wrap: true,
        },
        {
            name: <div className="title-format">{t("master.Open Price")}</div>,
            selector: row => row['open_price'],
            sortable: true,
            center: true,
            minWidth: '60px',
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("master.S/L")}</div>,
            selector: row => row['sl'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
        
        },
        {
            name: <div className="title-format">{t("master.T/P")}</div>,
            selector: row => row['tp'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("master.Price")}</div>,
            selector: row => row['price'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
        },
        {
            name: <div className="title-format">{t("master.Commission")}</div>,
            selector: row => row['commission'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.commission < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.commission > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
            ],
        },
        {
            name: <div className="title-format">{t("master.Swap")}</div>,
            selector: row => row['swap'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.swap < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.swap > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
            ],
        },
        {
            name: <div className="title-format">{t("master.Profit")}</div>,
            selector: row => row['profit'],
            sortable: true,
            center: true,
            minWidth: '50px',
            wrap: true,
            compact: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.profit < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.profit > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
            ],
        },     
    ];

    let menu = props.triggerFollower ? 'open' : 'closed';
    
  return (
      <div className={`                     
                      ${'containerUsers-' + menu}
                  `}>
        <div className="labelTable">
            {t("subAccount.Sub Account Orders")}
            <input
                className='filterBox'
                type="text"
                placeholder={t("master.Search...")}
                value={filterValue}
                onChange={handleFilterChange}
            />
        </div>
        <div className="table-responsive">
          <DataTable
            columns={columnas}
            data={filteredData}
            customStyles={customStyles}
            title={""}
            pagination
            paginationComponentOptions={{ rowsPerPageText: t("master.Rows per Page"), rangeSeparatorText: t("master.of"), selectAllRowsItem: true, selectAllRowsItemText: t("master.All"),}}
            paginationServer
            paginationTotalRows={props.totalSubOperations}
            onChangeRowsPerPage={props.SubAccountOrdersPerRowsChange}
            onChangePage={props.SubAccountOrdersPageChange}
            fixedHeader                       
          />
        </div>
      </div>
  );
}


export default SubAccountOperationsTable;
