import Layout from "./components/Login/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginForm from "./components/Login/LoginForm";
import Master from "./components/Master/Master";
import Subaccount from "./components/SubAccount/Subaccount";
import Swagger from "./components/404/Swagger";
import NotFound from "./components/404/notFound";


export const baseEnv = ``;/*`/${process.env.REACT_APP_ENV}/${process.env.REACT_APP_SERVER}`;*/
export const env = ``; /*process.env.REACT_APP_ENV;*/
export const server = ``; /*process.env.REACT_APP_SERVER;*/


function App(props) {

  return (
      <BrowserRouter>
          <Routes>
              <Route path={baseEnv + "/"} element={
                  <Layout>
                      <LoginForm/>
                  </Layout>
              } />
              <Route exact path={baseEnv + "/Master"} element={                 
                      <Master />                           
              } />
              <Route exact path={baseEnv + "/Subaccount"} element={
                  <Subaccount/>
              } />
              <Route exact path={baseEnv + "/Swagger"} element={
                  <Swagger/>
              } />
              <Route exact path="*" element={
                <NotFound/>
              } />
          </Routes>
      </BrowserRouter>    
  );
}

export default App;
