//Singleton script tha contains all the request uses in both main components (Signal and Follower)
import axios from "axios";
import Cookies from "universal-cookie";

//Main path builds depending on the cookie save when user select a platform and server in login form
const cookies = new Cookies();
const url = cookies.get("url");

let baseUrl = `${url}/api`;

//Function in charge of updating baseUrl variable with the new value catching on event listener
function manejarCookieActualizada(event) {
        baseUrl = `${event.detail.value}/api`;
      }
      
// Event listener for updating url variable when is change in login form
window.addEventListener('updatedUrl', manejarCookieActualizada);
//It's not being used in this moment
const getSettingLogs = async credentials => {
        const res= await axios.post(`${baseUrl}/getSlaves`, credentials);
        return res;       
};

//Request that brigns the status information about follower or signal providers account
const getStatus = async (headers, quantity, page) => {
    const res= await axios.get(`${baseUrl}/information?quantity=${quantity}&page=${page}`, 
        {headers}
    );  
    return res;       
};

//Request that brigns the information about a signal or follower account
const getInformation = async (headers, params) => {
    const res= await axios.post(`${baseUrl}/trades`, params, {headers}
    );
    return res;       
};

//Request that brigns the client's ip
const getIpClient = async () => {
      const res = await axios.get('https://api64.ipify.org?format=json');
      return res; 
};

//Request that log out the user logged in that moment
const logOut = async (headers) => {
    const res= await axios.delete(`${baseUrl}/login`, {headers});
    return res;       
};


const methods = { 
    getSettingLogs, 
    getStatus,
    getInformation,
    getIpClient,
    logOut
}

export default methods;