//Component in charge to control the time sesion expiration in every account log in communitraders web
import { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import Modal from "react-modal";
import swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import userService from "../services/user";
import { baseEnv } from "../App";
import Cookies from "universal-cookie";


//Modal.setAppElement('#root');

function IdleTimerContainer() {

    //-----------------------------------------------------------------------
    // Hooks, const and variables declaration
    //-----------------------------------------------------------------------

    const cookies = new Cookies();
    const token = cookies.get("token");
    const idleTimeRef = useRef(null);
    const navigate = useNavigate();
    let headers = { //Headers preparation, the token is get from cookies, the same token obtained when the user log in
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + token
    };
    
    //Function in charge of making the log out request of the any type of account if the max inactivity time is reached
    function logOut() {
        userService.logOut( //Calls the method stopFollowing from follower service (axios request), its passed the headers
            headers
        )
        .then(response=>{ //Waits for a response from the axios request
            return response.data;
        })
        .then(response=>{
            if(response.status === "Success"){                   
                console.log("Log out Successful");
            }
            else{
                console.log("Log out without response");
            }
        })
        .catch(error => {
                console.log(error);
            }         
        ); 
        navigate(baseEnv + "/"); //Sends user to login screen
    };

    //Function handlers calls when the max inactivity time is reached
    const onIdle = () => {
        console.log("Log Out");
        swal.fire({
            text: "Sesion timeout",
            icon: "warning",
            iconColor: "red",
            color: "#363636",
            background: "#FFFFFF",
            confirmButtonColor: "#FF0000"
        });
        //Function handler triggered when user presses the button log out, it sends directly to login view.   
        logOut();    
    };

    //Main component in charge to set the maximun time of inactivity, this component is called in every parent component of communitraders web
    const IdleTimer = useIdleTimer({
        crossTab: true,
        ref: idleTimeRef,
        timeout: 5 * 60 * 1000,
        onIdle: onIdle,
    });
    return (
        <div>           
            < div idletimer={IdleTimer} ></div >
        </div>
        

    ); 
}


export default IdleTimerContainer;