import {Spinner} from "reactstrap";
import classes from "../../css/LoginForm.module.scss";

function LoadingLogin(props){
    if(!props.loading) return null;
    else{
        return(
            <div className={classes.loaderParentLogin}>
                <div className={classes.loaderChildrenLogin}>
                     <Spinner color="danger"/>
                </div>              
            </div>  
        );
    }  
}

export default LoadingLogin;