import React, { useEffect, useState } from 'react';
import axios from 'axios';



const Swagger = () => {
    const baseUrl = `${process.env.REACT_APP_URL}`;
    const [swaggerData, setSwaggerData] = useState(null);

    useEffect(() => {
        const fetchSwaggerData = async () => {
            try {
                const response = await axios.get(baseUrl + '/swagger');
                setSwaggerData(response.data);
            } catch (error) {
                console.error('Error fetching Swagger data:', error);
            }
        };

        fetchSwaggerData();
    }, []);

    if (!swaggerData) {
        return <div>Cargando la documentaci�n de Swagger...</div>;
    }

    // Renderiza la documentaci�n de Swagger utilizando los datos obtenidos
    return (
        <div>
            <pre>{JSON.stringify(swaggerData, null, 2)}</pre>
        </div>
    );
};

export default Swagger;