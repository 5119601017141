//Singleton script tha contains all the request uses in Signal provider components
import axios from "axios";
import Cookies from "universal-cookie";

//Main path builds depending on the cookie save when user select a platform and server in login form
const cookies = new Cookies();
const url = cookies.get("url");

let baseUrl = `${url}/api`;

//Function in charge of updating baseUrl variable with the new value catching on event listener
function manejarCookieActualizada(event) {
        baseUrl = `${event.detail.value}/api`;
      }
      
// Event listener for updating url variable when is change in login form
window.addEventListener('updatedUrl', manejarCookieActualizada);

//Request that bring the list followers than is following the signal provider
const getSubOrders = async (quantity, page, login, headers) => {
        const res= await axios.get(`${baseUrl}/trades?login=${login}&quantity=${quantity}&page=${page}`, {headers});
        return res;       
};

const methods = { 
    getSubOrders, 
};

export default methods;